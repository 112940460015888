.productInfo {
  width: 100%;
  min-height: calc(100vh - 123px);
  background: #e3e8ee;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-wrapper {
  height: auto;
  background: #fff;
  border: 1px solid #cdd1d6;
  border-radius: 4px;
  padding: 30px;
}

.swiperr-main {
  padding: 10px;
  width: 100%;
  height: auto !important;
}
.swiper_slide1 {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  border: 2px solid rgb(131, 128, 128);
  border-radius: 10px;
  cursor: pointer;
}
.swiper_slide2 {
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px !important;
  border: 2px solid rgb(131, 128, 128);
}
.swiper_slide1 img {
  border-radius: 10px;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper_slide2 img {
  border-radius: 10px;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper_slide1 {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  cursor: pointer;
  height: 180px;
  box-sizing: border-box;
  padding: 10px;
}

.mySwiper2 .swiper_slide2 {
  width: 25%;
  height: 100%;
  opacity: 0.7;
}

.mySwiper2 .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper_slide1 img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* product detail */

.product-wrap {
  /* border: 1px solid red; */
  height: auto;
  padding: 20px;
  width: 100%;
  /* height: 100%; */
}

.product-info__title {
  font-size: 20px;
  font-weight: 700;
  padding: 20px;
  /* border: 1px solid green; */
}

.product-info__detail {
  margin-top: 10px;
  display: flex;
  padding: 20px;
  line-height: 30px;
  border-bottom: 1px solid #cdd1d6;
  color: #505152;
}

.product-info__detail div {
  width: 50%;
}

.product-info__price {
  height: 80px;
  display: flex;
  width: 100%;
  gap: 10px;
}

.product-info__price div {
  border: 1px solid red;
  width: 50%;
  background: #e3e8ee;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}

.product-detail__btn {
  margin-top: 50px;
  padding: 20px;
}

/* gallery corusel media */
@media (max-width: 1200px) {
  .swiper_slide1 {
    height: 400px;
  }
  .swiper_slide2 {
    height: 100px !important;
  }
  .mySwiper2 {
    height: 120px;
    box-sizing: border-box;
    padding: 10px;
  }

  /* prduct_detail */
  .product-info__title {
    font-size: 18px;
    font-weight: 700;
  }
  .product-info__price div {
    font-size: 18px;
    font-weight: 600;
  }
  .product-info__detail {
    line-height: 30px;
  }
}
@media (max-width: 992px) {
  .swiper_slide1 {
    height: 350px;
  }
  .swiper_slide2 {
    height: 80px !important;
  }

  .mySwiper2 {
    height: 100px;
    box-sizing: border-box;
    padding: 10px;
  }

    /* prduct_detail */
    .product-info__title {
      font-size: 15px;
      font-weight: 700;
    }
    .product-info__price div {
      font-size: 15px;
      font-weight: 600;
    }
    .product-info__detail {
      line-height: 30px;
    }
}
@media (max-width: 767px) {
  .swiper_slide1 {
    height: 500px;
  }
  .swiper_slide2 {
    height: 100px !important;
  }

  .mySwiper2 {
    height: 150px;
    box-sizing: border-box;
    padding: 8px;
  }
  .swiperr-main {
    padding: 8px;
    width: 100%;
    height: auto !important;
  }

    /* prduct_detail */
    .product-info__title {
      font-size: 12px;
      font-weight: 700;
    }
    .product-info__price div {
      font-size: 15px;
      font-weight: 600;
    }
    
    .product-info__detail {
      line-height: 30px;
    }
}
@media (max-width: 480px) {
  .swiper_slide1 {
    height: 400px;
  }
  .swiper_slide2 {
    height: 100px !important;
  }
  .mySwiper2 {
    height: 120px;
    box-sizing: border-box;
    padding: 5px;
  }
  .swiperr-main {
    padding: 5px;
    width: 100%;
    height: auto !important;
  }
    /* prduct_detail */
    .product-info__title {
      font-size: 10px;
      font-weight: 700;
    }
    .product-info__price div {
      font-size: 10px;
      font-weight: 600;
    }
   
    .product-info__detail {
      line-height: 30px;
    }

}

.signup-icon {
  font-size: 25px;
  cursor: pointer;
}

.modal-dialog {
  margin-top: 90px;
  padding: 30px;
}

.modal-body {
  padding: 40px;
  color: #000;
}

.modal-body h4 {
  font-weight: 600;
}

.footer-main {
  padding-top: 90px;
  background-color: #1b6392;
}

.address-main > div.col-lg-4 {
  border-bottom: dotted 1px rgb(134, 15, 15);
  display: flex;
  justify-content: center;
  align-items: center;
}

.addIcon {
  font-size: 40px;
  height: 100%;
  color: #ffffff;
  padding: 5px;
}

.addContent {
  padding: 10px;
  color: #ffff;
}

.addContent h5 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 18px;
  color: #ffffff;
  padding: 0;
  font-weight: 500;
  margin-bottom: 10px;
}

.addContent p {
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
}

.addContent a {
  color: #ffff;
}

.address-main > div.col-lg-4 {
  border-bottom: dotted 1px #999;
}

section {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15%;
}

section .social-link {
  text-decoration: none;
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
}

section .social-link:hover {
  border: 1px solid #27303b;
  color: #ffff;
  background: #27303b;
}
.copyright {
  text-align: center;
  padding: 10px;
  background: #27303b;
  color: #ffff;
}

/* ///////////////////////////////////////////////////////////// */

.social-media {
  display: flex;
  justify-content: space-around;
}
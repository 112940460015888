/* empty cart style */

.empty-cart {
  text-align: center;
}

.empty-cart h1 {
  margin-top: 200px;
}

.empty-cart h3 {
  margin-top: 20px;
  font-weight: 600;
}

.empty-cart p {
  margin-top: 20px;
}
/* extra style */

@media (min-width: 1025px) {
  .h-custom {
    height: 100vh !important;
  }
}

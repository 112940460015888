.search-input {
  border-radius: 0 !important;
  border: none !important;
  width: 100%;
  padding: 5px;
}
.search-input:focus {
  outline: none !important;
}
.search-icon {
  padding: 7px;
  color: #000;
  height: 100%;
  width: 40px;
  background: #fff;
  outline: none !important;
}

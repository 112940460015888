.products {
  padding-bottom: 20px;
}
.content-title {
  font-size: 24px;
  padding: 10px 0;
}
.products-col{
  padding: 8px;
}
.product-cardd {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.product-img {
  height: 350px;
  width: 100%;
}
.product-img img {
  height: 100%;
  width: 100%;
}
.product-title {
  color: #191c1f;
  font-weight: 700;
  font-size: 17px;
}
.product-price { 
  color: #2da5f3;
  font-size: 17px;
  font-weight: 600;
}

@media (max-width: 1400px) {
  .product-img {
    height: 320px;
    width: 100%;
  }
  .product-title {
    color: #191c1f;
    font-weight: 700;
    font-size: 15px;
  }
  .product-price {
    color: #2da5f3;
    font-size: 14px;
    font-weight: 600;
  }
}
@media (max-width: 992px) {
  .product-img {
    height: 260px;
    width: 100%;
  }
  .product-title {
    color: #191c1f;
    font-weight: 700;
    font-size: 15px;
  }
  .product-price {
    color: #2da5f3;
    font-size: 14px;
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .product-img {
    height: 370px;
    width: 100%;
  }
  .product-title {
    color: #191c1f;
    font-weight: 700;
    font-size: 18px;
  }
  .product-price {
    color: #2da5f3;
    font-size: 17px;
    font-weight: 600;
  }
}

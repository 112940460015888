.currecy {
  display: flex;
  justify-content: space-between;
  border: 2px solid rgb(14, 145, 197);
  padding: 5px;
  border-radius: 5px;
  background-color: #efefef;
}

.valyuta_main {
  display: flex;
  justify-content: center;
}

.valyuta_main input {
  width: 80%;
  padding: 6px;
  border-radius: 5px;
  border: 2px solid rgb(14, 145, 197);
}

.valyuta_main input:focus {
  width: 80%;
  padding: 6px;
  border-radius: 5px;
  outline-color: rgb(14, 145, 197);
}

.valyuta_main select {
 display: flex;
 justify-content: center;
  color: black;
  width: 17%;
  border-radius: 5px;
  border: 2px solid rgb(14, 145, 197) !important;
}

.valyuta_main select option {
  border: 2px solid rgb(14, 145, 197) !important;
  outline:  rgb(14, 145, 197) !important;
}

.valyuta_main select option :focus {
  border: 2px solid rgb(14, 145, 197) !important;
  outline:  rgb(14, 145, 197) !important;
}

.header {
  background-color: #1b6392;
  color: #fff;
  height: 123px;
}

/* .ReactFlagsSelect-module_selectBtn__19wW7{
  background-color: none !important;
  height: 30px !important;
  color: #fff !important;
}
.ReactFlagsSelect-module_selectOptions__3LNBJ{
  border: 1px solid red;
}
.ReactFlagsSelect-module_label__27pw9{
  color: black !important;
} */

select {
  border: none;
  outline: none;
  border-radius: 3px;
  background: none;
  color: #ffff;
  cursor: pointer;
  appearance: none;
  padding: 0 4px;
}
select option {
  border-radius: 5px !important;
  color: #000;
  padding: 22px;
  border: none !important;
  cursor: pointer;
}
/* top-nav */
.top-nav {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.phone-icon {
  font-size: 20px;
}
.top-nav .social-main {
  gap: 7px;
  display: flex;
}
.top-nav .social-main .vl {
  border-left: 1px solid rgb(177, 174, 174);
  height: auto;
}
.top-nav .social-main a {
  color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-nav .social-main .language {
  position: relative;
  cursor: pointer;
}
.top-nav .social-main .language .language_main {
  display: flex;
  gap: 2px;
}
.top-nav .social-main .language .flag_img {
  width: auto;
  display: flex;
  justify-content: flex-end;
  width: 20px;
}
.top-nav .social-main .language .flag_img img {
  width: 100%;
}
.top-nav .social-main .language ul {
  padding: 0;
}
.top-nav .social-main .language ul li {
  list-style: none;
}

/* middile-nav */
.middle-nav {
  padding: 10px 0;
  display: flex;
  gap: 5px;
  height: auto;
  align-items: center;
  justify-content: space-between;
}
.logo {
  gap: 5px;
  display: flex;
  align-items: center;
}
.logoImg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.logo-name {
  height: 100% !important;
  text-transform: uppercase;
  font-family: sans-serif;
  font-weight: 700;
  /* font-size: 32px; */
  line-height: 40px;
}

.header-search {
  /* position: relative; */
  width: 400px;
  height: 38px;
  display: flex;
}

.middle-nav-icons {
  display: flex;
  gap: 8px;
  height: auto;
  align-items: center;
  color: #ffff;
}

.middle-nav-icons a {
  color: #ffff !important;
  font-size: 30px;
  display: flex;
  align-items: center;
  height: 100%;
}
.mobile-serach {
  display: none !important;
}
#basic-addon2 {
  border-top-right-radius: none !important;
  margin: 0 !important;
  background-color: none !important;
  border: 1px solid red !important;
  width: auto !important;
}
.input-group input {
  width: 100px !important;
}

.currency_icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 576px) {
  .header {
    padding: 0 5px;
  }
  .header-search {
    width: 250px;
    height: 30px;
    display: flex;
  }
  .logoImg img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .logo-name {
    font-size: 20px;
  }
  .middle-nav-icons a {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .header {
    border: 1px solid red;
    padding: 0 5px;
  }
  .header-search {
    display: none;
  }
  .logoImg img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .logo-name {
    font-size: 20px;
  }
  .middle-nav-icons a {
    font-size: 20px;
  }
  .social-title {
    display: none;
  }
  .mobile-serach {
    display: block !important;
  }
}

.corusell {
  width: 100%;
  height: calc(80vh - 123px);
  padding: 20px 0;
  box-sizing: border-box;
}
.swapper{
  width: 100% !important;
  height: 100% !important;
}
.corusel-img {
  width: 100% !important;
  height: auto;
  object-fit: cover;
  display: block;
}
.banner-swiper {
  width: 100%;
  height: 100% !important;
}

.swiper-slide-banner {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide-banner img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .main {
  position: relative;
} */
.corusel-title {
  padding: 10px;
  position: absolute;
  top: 60%;
  left: 25%;
  width: 50%;
  text-align: center;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}
.corusel-title h5 {
  font-size: 40px;
  font-weight: 700;
}
.corusel-title p {
  font-size: 30px;
}
@media (max-width: 992px) {
  .corusell {
    height: auto;
    padding: 20px 0;
  }
}
@media (max-width: 768px) {
  .corusell {
    height: auto;
    padding: 20px 0;
  }
  .corusel-title h5 {
    font-size: 35px;
    font-weight: 500;
  }
  .corusel-title p {
    font-size: 25px;
  }
}
@media (max-width: 576px) {
  .corusell {
    height: auto;
    padding: 20px 0;
  }
  .corusel-title h5 {
    font-size: 30px;
    font-weight: 500;
  }
  .corusel-title p {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .corusell {
    height: auto;
    padding: 20px 0;
  }
  .corusel-title h5 {
    font-size: 20px;
    font-weight: 500;
  }
  .corusel-title p {
    font-size: 10px;
  }
}

@media (max-width: 454px) {
  .corusel-title h5 {
    font-size: 15px;
    font-weight: 500;
  }
  .corusel-title p {
    font-size: 5px;
  }
}
